import React, { useMemo, useState } from "react";
import { hebStaticRanges } from "../../../../components/datepicker/const";
import DateRangePicker from "../../../../components/datepicker/DateRangePicker";
import DefinedRange from "../../../../components/datepicker/date-range-picker/DefinedRange";
import Dropdown from "../../../../components/dropdown/Dropdown";
import DropdownSelect from "../../../../components/dropdown/DropdownSelect";
import { Button, Grid, Menu, MenuItem } from "@mui/material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import he from "date-fns/locale/he";
import MultiSelect from "../../../../components/dropdown/MultiSelect";
import { useScreenSize } from "../../../../Hooks";
import { connect, Provider, useDispatch, useSelector } from "react-redux";
import MultiSelectCheckbox from "../../../../components/dropdown/MultiSelectCheckBox";

const WorkForceFilter = ({
  selectedWorkForceEnableFilter,
  setSelectedWorkForceEnableFilter,
  isFilterSubmissionDates,
  setIsFilterSubmissionDates,
  isFilterCreationDates,
  setIsFilterCreationDates,
  isFilterAttendanceDate,
  setIsFilterAttendanceDate,
  selectedDates,
  setSelectedDates,
  selectedWorkForceFormsFilter,
  setSelectedWorkForceFormsFilter,
  checkedBranches,
  setCheckedBranches,
}) => {
  const branches = Object.values(
    useSelector((state) => state.branches.branches)
  ).map((x) => ({
    label: x.name,
    value: x.id,
  }));
  const branchOptions = useMemo(() => [...branches], [branches]);
  const { isMobile } = useScreenSize();

  const work_force_enable_filter_options = useMemo(
    () => [
      { label: "פעילים", value: 1 },
      { label: "לא פעילים", value: 0 },
      { label: "ללא מס' במערכת שכר", value: 2 },
      { label: "ללא מס' ת.ז", value: 3 },
    ],
    []
  );
  const work_force_forms_filter_options = useMemo(
    () => [
      { label: "ללא טופס 101", value: "missing101" },
      { label: "ללא הסכם עבודה", value: "missingWorkAgreement" },
      { label: "ללא טפסים נוספים", value: "missingOtherForms" },
    ],
    []
  );

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const openFilterMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const closeFilterMenu = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <div style={isMobile ? {} : { marginLeft: "2rem" }}>
        <label>
          מצב העובד
          {/* <DropdownSelect
            style={{ maxWidth: "100%" }}
            selected={selectedWorkForceEnableFilter}
            setSelected={setSelectedWorkForceEnableFilter}
            options={work_force_enable_filter_options}
            isSearchable={false}
          /> */}
          <MultiSelectCheckbox
            title="מצב העובד"
            checked={selectedWorkForceEnableFilter}
            setChecked={setSelectedWorkForceEnableFilter}
            options={work_force_enable_filter_options}
          />
        </label>
      </div>
      <div style={isMobile ? {} : { marginLeft: "2rem" }}>
        <label>
          סניף
          <MultiSelectCheckbox
            title="בחר סניף"
            checked={checkedBranches}
            setChecked={setCheckedBranches}
            options={branchOptions}
          />
        </label>
      </div>
      <div
        style={isMobile ? {} : { marginLeft: "2rem" }}
        className="flex-column-right"
      >
        <label>סנן לפי סטאטוס</label>
        <Button
          id="dateFilterButton"
          aria-controls={open ? "dateFilterMenu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={openFilterMenu}
          size="large"
          variant="outlined"
          startIcon={<FilterAltIcon />}
        >
          סנן לפי סטאטוס:
        </Button>
        <Menu
          id="dateFilterMenu"
          aria-labelledby="dateFilterButton"
          anchorEl={anchorEl}
          open={open}
          onClose={closeFilterMenu}
        >
          <MenuItem>
            <div>
              <label className="checkbox--container">
                תאריך הגשה
                <input
                  type="checkbox"
                  checked={isFilterSubmissionDates}
                  onChange={(e) => setIsFilterSubmissionDates(e.target.checked)}
                />
                <span className="checkbox--checkmark"></span>
              </label>
            </div>
          </MenuItem>
          <MenuItem>
            <div>
              <label className="checkbox--container">
                תאריך הקמה
                <input
                  type="checkbox"
                  checked={isFilterCreationDates}
                  onChange={(e) => setIsFilterCreationDates(e.target.checked)}
                />
                <span className="checkbox--checkmark"></span>
              </label>
            </div>
          </MenuItem>
          <MenuItem>
            <div>
              <label className="checkbox--container">
                עובדים ללא נוכחות
                <input
                  type="checkbox"
                  checked={isFilterAttendanceDate}
                  onChange={(e) => setIsFilterAttendanceDate(e.target.checked)}
                />
                <span className="checkbox--checkmark"></span>
              </label>
            </div>
          </MenuItem>
        </Menu>
      </div>
      {isFilterSubmissionDates ||
      isFilterCreationDates ||
      isFilterAttendanceDate ? (
        <div style={isMobile ? {} : { marginLeft: "2rem" }}>
          <label>
            תאריך סינון
            <Dropdown
              style={
                isMobile
                  ? {
                      minWidth: "100%",
                      fontSize: "1.4rem",
                      display: "block",
                      margin: "0",
                    }
                  : {
                      maxWidth: "16rem",
                      fontSize: "1.4rem",
                      display: "block",
                    }
              }
              contentStyle={{ width: "fit-content" }}
              text={selectedDates.label}
            >
              {isMobile ? (
                <DefinedRange
                  ranges={[selectedDates]}
                  staticRanges={hebStaticRanges}
                  local={he}
                  onChange={(item) => setSelectedDates(item.selection)}
                />
              ) : (
                <DateRangePicker
                  selectedRange={selectedDates}
                  setSelectedRange={setSelectedDates}
                />
              )}
            </Dropdown>
          </label>
        </div>
      ) : (
        ""
      )}

      <div style={isMobile ? {} : { marginLeft: "2rem", minWidth: "16rem" }}>
        <label>
          סטאטוס טפסים
          <MultiSelect
            selected={selectedWorkForceFormsFilter}
            setSelected={setSelectedWorkForceFormsFilter}
            options={work_force_forms_filter_options}
            placeholder="בחר טפסים"
          />
        </label>
      </div>
    </>
  );
};

export default WorkForceFilter;
