import FormTextField from "../../../components/Forms/inputs/FormTextField";
import { REQUIRED_MESSAGE } from "../../../components/Forms/constants";
import SelectComponent from "../../../components/Forms/inputs/Select";
import FormCheckBox from "../../../components/Forms/inputs/FormCheckBox";
import moment from "moment";
import { ControlAutoComplete } from "../../../components/Forms/inputs/AutoComplete";

const PART_EMPLOYEE_NAME = 0;
const PART_TYPE_IDENTIFER = 1;
const PART_DATE = 2;
// const PART_WORKER_SIGNATURE = 3;
const PART_REASON_RESIGNATION = 3;
const INPUT = "שדה";
const REQUIRED_CHECKBOX = {
  name: "required",
  component: FormCheckBox,
  label: "שדה חובה",
  defaultValue: true,
};

const INPUT_LABEL_FIELD = (defaultValue = "") => ({
  name: "label",
  component: FormTextField,
  label: "כותרת השדה",
  defaultValue,
  rules: { required: REQUIRED_MESSAGE },
});

const PARTS = {
  [PART_EMPLOYEE_NAME]: {
    label: "שם העובד",
    defaultValue: () => "שם העובד",
    extraData: [
      REQUIRED_CHECKBOX,
      INPUT_LABEL_FIELD("שם העובד"),
      {
        name: "value",
        component: FormTextField,
        disabled: true,
      },
    ],
    show: {
      rules: { required: REQUIRED_MESSAGE },
      component: ControlAutoComplete,
      size: "small",
      inputProps: { label: "שם העובד" },
    },
  },
  [PART_TYPE_IDENTIFER]: {
    label: "ת.ז.",
    defaultValue: () => "ת.ז.",
    extraData: [
      REQUIRED_CHECKBOX,
      INPUT_LABEL_FIELD("ת.ז."),
      {
        name: "value",
        component: FormTextField,
        placeholder: "ת.ז.",
        disabled: true,
      },
    ],
    show: {
      component: FormTextField,
      type: "text",
      fullWidth: true,

      rules: {
        pattern: {
          value: new RegExp(/^\d{0,9}$/),
          message: "מספר ת.ז. לא תקין יש לנסות שוב",
        },
      },
    },
  },
  [PART_DATE]: {
    label: "תאריך",
    defaultValue: () => "תאריך",
    extraData: [
      REQUIRED_CHECKBOX,
      INPUT_LABEL_FIELD("תאריך"),
      {
        name: "value",
        component: FormTextField,
        fullWidth: true,

        placeholder: "תאריך",
        disabled: true,
      },
    ],
    show: { component: FormTextField, type: "date", fullWidth: true },
  },
  [PART_REASON_RESIGNATION]: {
    label: "סיבת התפטרות",
    defaultValue: () => "סיבת התפטרות",
    extraData: [
      REQUIRED_CHECKBOX,
      INPUT_LABEL_FIELD("סיבת התפטרות"),
      {
        name: "value",
        component: FormTextField,
        placeholder: "סיבת התפטרות",
        disabled: true,
      },
    ],
    show: {
      component: FormTextField,
      multiline: true,
      rows: 9,
      fullWidth: true,
    },
  },
};
export {
  PARTS,
  PART_EMPLOYEE_NAME,
  INPUT,
  PART_TYPE_IDENTIFER,
  PART_DATE,
  PART_REASON_RESIGNATION,
};
