import {
  fixDate,
  fixHour,
  numberFormat,
  twoZeroAfterDot,
} from "../../../../helpers";
import React, { useEffect, useMemo, useState, useRef } from "react";
import BtnTooltip from "../../../../components/tooltips/BtnTooltip";
import styled from "styled-components";
import { CircularProgress, TextField, Typography } from "@mui/material";
import { FaCheck, FaSave, FaTimes } from "react-icons/fa";
import {
  COLOR_ABORT,
  COLOR_CONFIRM,
  COLOR_RESTIGO_PRIMARY,
} from "../../../../const/colors";
import moment from "moment";
import { IconButton } from "@mui/material";
// import CustomHoursInput from "../../../../components/inputs/CustomHoursInput";
import { isNumeric } from "validator";
import { isEmpty } from "lodash";
import Button from "../../../../components/buttons/Button";
import { PriceCell } from "../../../../components/tables/components/PriceFooter";
import { IoMdPricetag } from "react-icons/io";
const IconInfo = styled.i`
  border-radius: 100px;
  width: 1.6rem;
  height: 1.6rem;
  background-color: #eaeaea;
  text-align: center;
  line-height: 1.7;
  font-size: 10px;
  margin-right: 0.3rem;
`;

export const aggregate_tip_columns = [
  { field: "tipHours", aggregate: "sum" },
  {
    field: "totalBeforeAllowance",
    aggregate: "sum",
  },
  {
    field: "totalAllowance",
    aggregate: "sum",
  },
  {
    field: "minimumGap",
    aggregate: "sum",
  },
  {
    field: "totalAllowanceFromCredit",
    aggregate: "sum",
  },
  {
    field: "totalAllowanceFromCash",
    aggregate: "sum",
  },
  {
    field: "totalCashTipForEvent",
    aggregate: "sum",
  },
  {
    field: "totalCardTipsForEvent",
    aggregate: "sum",
  },
  {
    field: "totalTipForEvent",
    aggregate: "sum",
  },
];

export const tip_columns = [
  {
    Header: "עובד",
    accessor: "employeeName",
    Footer: 'סה"כ',
  },
  {
    Header: "תפקיד",
    accessor: "eventRole",
  },
  {
    Header: "כניסה",
    accessor: "actualClockIn",
    Cell: (props) => `${fixHour(props.row.original.actualClockIn)}`,
  },
  {
    Header: "יציאה",
    accessor: "actualClockOut",
    Cell: (props) => `${fixHour(props.row.original.actualClockOut)}`,
  },
  {
    Header: "סה״כ",
    accessor: "tipHours",
    Cell: (props) => Number(props.row.original.tipHours).toFixed(2),
    Footer: (self) => {
      const total = useMemo(
        () => self.rows.reduce((sum, row) => row.values.tipHours + sum, 0),
        [self.rows]
      );

      return <>{twoZeroAfterDot(total)}</>;
    },
  },

  {
    Header: "טיפ לפני",
    accessor: "hourlyTipBruto",
    Cell: (props) => (
      <div className="money ">
        <span>&#8362;</span>
        {twoZeroAfterDot(props.row.original.hourlyTipBruto)}
      </div>
    ),
  },
  {
    Header: "הפרשה",
    accessor: "allowance_method_amount",
    Cell: (props) => {
      return (
        <div
          className="money "
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <span>&#8362;</span>
          {numberFormat(
            props.row.original.totalAllowanceFromCash +
              props.row.original.totalAllowanceFromCredit
          )}

          <BtnTooltip
            text={`${props.row.original.allowance_method_amount} ${
              props.row.original.allowance_method === 2 ? "%" : "₪"
            }`}
          >
            <IconButton size="small" color="secondary">
              {IoMdPricetag()}
            </IconButton>
          </BtnTooltip>
        </div>
      );
    },
  },
  {
    Header: "טיפ אחרי",
    accessor: "hourlyTip",
    Cell: (props) => (
      <div className="money ">
        <span>&#8362;</span>
        {twoZeroAfterDot(props.row.original.hourlyTip)}
      </div>
    ),
  },
  {
    Header: 'סה"כ טיפ נטו',
    accessor: "totalTipForEvent",
    Cell: (props) => (
      <div className="money ">
        <span>&#8362;</span>
        {twoZeroAfterDot(props.row.original.totalTipForEvent)}
      </div>
    ),
    Footer: (self) => {
      const total = useMemo(
        () =>
          self.rows.reduce((sum, row) => row.values.totalTipForEvent + sum, 0),
        [self.rows]
      );

      return (
        <div className="money money__footer">
          <span>&#8362;</span>
          {twoZeroAfterDot(total)}
        </div>
      );
    },
  },
  {
    Header: "השלמה",
    accessor: "minimumGap",
    Cell: (props) => (
      <div className="money ">
        <span>&#8362;</span>
        {twoZeroAfterDot(props.row.original.minimumGap)}
      </div>
    ),
    Footer: (self) => {
      const total = useMemo(
        () => self.rows.reduce((sum, row) => row.values.minimumGap + sum, 0),
        [self.rows]
      );

      return (
        <div className="money money__footer">
          <span>&#8362;</span>
          {twoZeroAfterDot(total)}
        </div>
      );
    },
  },
  {
    Header: (
      <div className="money ">
        מזומן
        <span>
          <BtnTooltip text='סה"כ טיפ מזומן לאחר הפרשה למסעדה'>
            <IconInfo className="fa fa-info" />
          </BtnTooltip>
        </span>
      </div>
    ),
    // Header: 'סה"כ מזומן',
    accessor: "totalCashTipForEvent",
    Cell: (props) => (
      <div className="money ">
        <span>&#8362;</span>
        {twoZeroAfterDot(props.row.original.totalCashTipForEvent)}
      </div>
    ),
    Footer: (self) => {
      const total = useMemo(
        () =>
          self.rows.reduce(
            (sum, row) => row.values.totalCashTipForEvent + sum,
            0
          ),
        [self.rows]
      );

      return (
        <div className="money money__footer">
          <span>&#8362;</span>
          {twoZeroAfterDot(total)}
        </div>
      );
    },
  },
  {
    Header: (
      <div className="money ">
        אשראי
        <span>
          <BtnTooltip text='סה"כ טיפ אשראי לאחר הפרשה למסעדה'>
            <IconInfo className="fa fa-info" />
          </BtnTooltip>
        </span>
      </div>
    ),
    // Header: 'סה"כ אשראי',
    accessor: "totalCardTipsForEvent",
    Cell: (props) => (
      <div className="money ">
        <span>&#8362;</span>
        {twoZeroAfterDot(props.row.original.totalCardTipsForEvent)}
      </div>
    ),
    Footer: (self) => {
      const total = useMemo(
        () =>
          self.rows.reduce(
            (sum, row) => row.values.totalCardTipsForEvent + sum,
            0
          ),
        [self.rows]
      );

      return (
        <div className="money money__footer">
          <span>&#8362;</span>
          {twoZeroAfterDot(total)}
        </div>
      );
    },
  },
  {
    Header: (
      <div className="money ">
        טיפ כולל
        <span>
          <BtnTooltip text="העמודה מציגה את סכום טיפ נטו + השלמה">
            <IconInfo className="fa fa-info" />
          </BtnTooltip>
        </span>
      </div>
    ),

    accessor: "total_tip",
    Cell: (props) => (
      <div className="money ">
        <span>&#8362;</span>
        {twoZeroAfterDot(
          props.row.original.totalTipForEvent + props.row.original.minimumGap
        )}
      </div>
    ),
    Footer: (self) => {
      const total = useMemo(
        () =>
          self.rows.reduce(
            (sum, row) =>
              row.values.totalTipForEvent + row.values.minimumGap + sum,
            0
          ),
        [self.rows]
      );

      return (
        <div className="money money__footer">
          <span>&#8362;</span>
          {twoZeroAfterDot(total)}
        </div>
      );
    },
  },
];

export const tip_columns_mobile = [
  {
    Header: "עובד",
    accessor: "employeeName",
    Footer: 'סה"כ',
    Cell: (props) => {
      const {
        eventRole,
        employeeName,
        actualClockIn,
        actualClockOut,
        hourlyTip,
        totalAllowanceFromCredit,
        totalAllowanceFromCash,
        totalCashTipForEvent,
        totalCardTipsForEvent,
        minimumGap,
        tipHours,
      } = props.row.original;
      const { setDialogModal, date } = props;
      const pStyling = {
        fontSize: "1.6rem",
        color: "#A6A6A6",
        fontWeight: 500,
      };
      const divStyling = {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        paddingBlock: "0.6rem",
      };
      const keys = [
        "תפקיד",
        "שעת כניסה",
        "שעת יציאה",
        "סה״כ שעות",
        "טיפ נטו לשעה",
        "הפרשה מאשראי",
        "הפרשה ממזומן",
        "סה״כ מזומן",
        "סה״כ אשראי",
        "השלמה לבסיס",
      ];
      const values = [
        eventRole,
        actualClockIn,
        actualClockOut,
        numberFormat(tipHours),
        numberFormat(hourlyTip, 0, 2, "currency"),
        numberFormat(totalAllowanceFromCredit, 0, 2, "currency"),
        numberFormat(totalAllowanceFromCash, 0, 2, "currency"),
        numberFormat(totalCashTipForEvent, 0, 2, "currency"),
        numberFormat(totalCardTipsForEvent, 0, 2, "currency"),
        numberFormat(minimumGap, 0, 2, "currency"),
      ];
      return (
        <div style={{ display: "flex" }}>
          <p>
            <span>
              {employeeName.split(" ")[0]}{" "}
              {employeeName.split(" ")[1].substring(0, 2)}...
            </span>
          </p>
          <IconButton
            size="small"
            color="secondary"
            onClick={() =>
              setDialogModal({
                iconJSX: () => IoMdPricetag({ size: "7em", color: "#00A2EA" }),
                title: `טיפים שחושבו בתאריך ${fixDate(date)} 
                לעובד/ת: ${employeeName}`,
                text: (
                  <section style={{ padding: "0.5rem 2rem" }}>
                    {keys.map((key, index) => (
                      <React.Fragment key={key}>
                        <div style={divStyling}>
                          <p style={{ ...pStyling, marginRight: "2rem" }}>
                            {key}
                          </p>
                          <p style={{ ...pStyling, marginLeft: "2rem" }}>
                            {values[index]}
                          </p>
                        </div>
                        <hr />
                      </React.Fragment>
                    ))}
                  </section>
                ),
                showAbortButton: false,
                onConfirm: () => {},
              })
            }
          >
            {IoMdPricetag()}
          </IconButton>
        </div>
      );
    },
  },
  {
    Header: "סה״כ שעות",
    accessor: "tipHours",
    Cell: (props) => (
      <span>{Number(props.row.original.tipHours).toFixed(2)}</span>
    ),
    Footer: (self) => {
      const total = useMemo(
        () => self.rows.reduce((sum, row) => row.values.tipHours + sum, 0),
        [self.rows]
      );
      return <>{numberFormat(total)}</>;
    },
  },
  {
    Header: "טיפ לפני",
    accessor: "hourlyTipBruto",
    Cell: (props) => (
      <div className="money ">
        <span>&#8362;</span>
        {numberFormat(props.row.original.hourlyTipBruto)}
      </div>
    ),
  },
  {
    Header: "טיפ אחרי",
    accessor: "hourlyTip",
    Cell: (props) => (
      <div className="money ">
        <span>&#8362;</span>
        {numberFormat(props.row.original.hourlyTip)}
      </div>
    ),
  },
  {
    Header: 'סה"כ טיפ',
    accessor: "totalTipForEvent",
    Cell: (props) => (
      <div className="money ">
        <span>&#8362;</span>
        {numberFormat(props.row.original.totalTipForEvent)}
      </div>
    ),
    Footer: (self) => {
      const total = useMemo(
        () =>
          self.rows.reduce((sum, row) => row.values.totalTipForEvent + sum, 0),
        [self.rows]
      );
      return <>{numberFormat(total, 0, 2, "currency")}</>;
    },
  },
];

export const error_employees_columns = (canEditSchedule) => [
  {
    noSort: true,
    Header: "עובד",
    accessor: "name",
  },
  {
    noSort: true,
    Header: "כניסה",
    accessor: "start",
    Cell: ({ row, saveManualHours, currentLoadingEventId }) => {
      const { id, start, end, type } = row.original;
      return type === "start" ? (
        canEditSchedule ? (
          <CustomHoursInput
            eventId={id}
            type="start"
            start={start ?? "00:00"}
            end={end ?? "00:00"}
            onSave={saveManualHours}
            currentLoadingEventId={currentLoadingEventId}
          />
        ) : (
          "לא קיימת שעת כניסה"
        )
      ) : (
        fixHour(start)
      );
    },
  },
  {
    noSort: true,
    Header: "יציאה",
    accessor: "end",
    Cell: ({ row, saveManualHours, currentLoadingEventId }) => {
      const { id, start, end, type } = row.original;

      return type === "end" ? (
        canEditSchedule ? (
          <CustomHoursInput
            eventId={id}
            type="end"
            start={start ?? "00:00"}
            end={end ?? "00:00"}
            onSave={saveManualHours}
            currentLoadingEventId={currentLoadingEventId}
          />
        ) : (
          "לא קיימת שעת יציאה"
        )
      ) : (
        fixHour(end)
      );
    },
  },
];

export const lock_day_error_employees_columns = (isMobile) =>
  isMobile
    ? [
        {
          noSort: true,
          Header: "עובד",
          accessor: "name",
        },
        {
          noSort: true,
          Header: "שעות",
          accessor: "hours",
          Cell: ({
            row,
            updateHours,
            currentLoadingEventId,
            saveManualHours,
          }) => {
            const { id, start, end, type } = row.original;
            const disabled = start == null || end == null;

            return (
              <div className="flex-start">
                <div className="flex-column-center">
                  <ManualHoursUpdateInput
                    eventId={id}
                    type="start"
                    start={start}
                    end={end}
                    onSave={updateHours}
                    currentLoadingEventId={null}
                    helperText="כניסה"
                  />
                  <ManualHoursUpdateInput
                    eventId={id}
                    type="end"
                    start={start}
                    end={end}
                    onSave={updateHours}
                    currentLoadingEventId={null}
                    helperText="יציאה"
                  />
                </div>
                <div style={{ paddingTop: "2.5rem", paddingRight: "1rem" }}>
                  {id === currentLoadingEventId ? (
                    <IconButton>
                      <CircularProgress color="primary" size={20} />
                    </IconButton>
                  ) : (
                    <IconButton
                      color="primary"
                      onClick={() => saveManualHours(id, start, end)}
                      disabled={disabled}
                    >
                      {FaSave()}
                    </IconButton>
                  )}
                </div>
              </div>
            );
          },
        },
      ]
    : [
        {
          noSort: true,
          Header: "עובד",
          accessor: "name",
        },
        {
          noSort: true,
          Header: "כניסה",
          accessor: "start",
          Cell: ({ row, updateHours }) => {
            const { id, start, end, type } = row.original;

            return (
              <ManualHoursUpdateInput
                eventId={id}
                type="start"
                start={start}
                end={end}
                onSave={updateHours}
                currentLoadingEventId={null}
              />
            );
          },
        },
        {
          noSort: true,
          Header: "יציאה",
          accessor: "end",
          Cell: ({ row, updateHours }) => {
            const { id, start, end } = row.original;

            return (
              <ManualHoursUpdateInput
                eventId={id}
                type="end"
                start={start}
                end={end}
                onSave={updateHours}
                currentLoadingEventId={null}
              />
            );
          },
        },
        {
          noSort: true,
          Header: "פעולות",
          accessor: "actions",
          Cell: ({ row, saveManualHours, currentLoadingEventId }) => {
            const { id, start, end } = row.original;
            const disabled = start == null || end == null;

            return id === currentLoadingEventId ? (
              <IconButton>
                <CircularProgress color="primary" size={20} />
              </IconButton>
            ) : (
              <IconButton
                color="primary"
                onClick={() => saveManualHours(id, start, end)}
                disabled={disabled}
              >
                {FaSave()}
              </IconButton>
            );
          },
        },
      ];

export const ManualHoursUpdateInput = ({
  eventId,
  type,
  onSave,
  start,
  end,
  currentLoadingEventId,
  helperText,
}) => {
  const [value, setValue] = useState(type === "start" ? start : end);
  const [error, setError] = useState(false);

  useEffect(() => {
    setValue(type === "start" ? start : end);
  }, [start, end, type]);

  const handleChange = (value) => {
    setError(false);
    setValue(value);
  };

  const handleSave = () => {
    if (!moment(value, "HH:mm", true).isValid()) {
      setError(true);
      return;
    }
    onSave(
      eventId,
      type === "start" ? value : start,
      type === "end" ? value : end
    );
  };

  return (
    <div>
      <TextField
        size="small"
        type="time"
        error={error}
        value={value}
        helperText={
          error ? (
            <strong style={{ color: COLOR_ABORT }}>שעה לא תקינה</strong>
          ) : helperText ? (
            <Typography variant="subtitle2" align="center" fontSize={4}>
              {helperText}
            </Typography>
          ) : (
            ""
          )
        }
        onChange={(e) => handleChange(e.target.value)}
        onBlur={() => handleSave()}
        inputProps={{
          className: "only-numbers",
          style: {
            textAlign: "center",
            width: "80px",
            fontSize: "1.2rem",
          },
        }}
      />
      {eventId === currentLoadingEventId && (
        <IconButton>
          <CircularProgress color="primary" size={20} />
        </IconButton>
      )}
    </div>
  );
};

export const CustomHoursInput = ({
  eventId,
  type,
  onSave,
  start,
  end,
  currentLoadingEventId,
  helperText,
  className,
  disabled = false,
  resetDisabled = false,
}) => {
  const [hour, setHour] = useState(
    type === "start"
      ? moment(start, "HH:mm").format("HH")
      : moment(end, "HH:mm").format("HH")
  );
  const [min, setMin] = useState(
    type === "start"
      ? moment(start, "HH:mm").format("mm")
      : moment(end, "HH:mm").format("mm")
  );
  const [error, setError] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const input1 = useRef(null);
  const input2 = useRef(null);

  useEffect(() => {
    setHour(
      type === "start"
        ? moment(start, "HH:mm").format("HH")
        : moment(end, "HH:mm").format("HH")
    );
    setMin(
      type === "start"
        ? moment(start, "HH:mm").format("mm")
        : moment(end, "HH:mm").format("mm")
    );
  }, [start, end, type]);

  const handleHourChange = (e) => {
    setError(false);
    setHour(e.target.value);
  };

  const handleMinChange = (e) => {
    setError(false);
    setMin(e.target.value);
  };

  const handleFocus = (e) => {
    setIsFocused(true);
  };

  const handleSave = () => {
    const value = `${hour}:${min}`;
    if (!moment(value, "HH:mm", true).isValid()) {
      setError(true);
      return;
    }
    onSave(
      eventId,
      type === "start" ? value : start,
      type === "end" ? value : end
    );
  };

  const reset = () => {
    setHour("--");
    setMin("--");
  };

  return (
    <div className={`flex-column-right`} style={{ lineHeight: "1.4" }}>
      <div
        className={`hours-input ${disabled ? "hours-input--disabled" : ""} ${
          isFocused ? "hours-input--focused" : ""
        }`}
      >
        <input
          disabled={disabled}
          ref={input1}
          type="text"
          name="hour"
          value={hour}
          onChange={handleHourChange}
          onFocus={handleFocus}
          onBlur={() => {
            handleSave();
            setIsFocused(false);
          }}
        />
        <div>:</div>
        <input
          disabled={disabled}
          ref={input2}
          type="text"
          name="min"
          value={min}
          onChange={handleMinChange}
          onFocus={handleFocus}
          onBlur={() => {
            handleSave();
            setIsFocused(false);
          }}
        />
      </div>
      {error && <p style={{ color: "red" }}>שעה לא תקינה</p>}
      {helperText && (
        <Typography variant="subtitle2" align="center" fontSize={4}>
          {helperText}
        </Typography>
      )}
      {eventId === currentLoadingEventId && (
        <IconButton>
          <CircularProgress color="primary" size={20} />
        </IconButton>
      )}
      {!disabled && (min !== "--" || hour !== "--") && !resetDisabled && (
        <Button
          onClick={reset}
          style={{ fontSize: "1rem" }}
          className="btn--hyperlink"
          textValue="איפוס"
        />
      )}
    </div>
  );
};

export const tip_logs = [
  {
    Header: "תאריך עדכון",
    accessor: "updated_at",
    Cell: ({ row }) => {
      const { updated_at } = row.original;

      // Create a Date object from the ISO 8601 formatted date string
      const dateObj = new Date(updated_at);

      // Format time as hh:mm
      const time = `${("0" + dateObj.getHours()).slice(-2)}:${(
        "0" + dateObj.getMinutes()
      ).slice(-2)}`;

      // Format date as DD/MM/YYYY
      const day = ("0" + dateObj.getDate()).slice(-2);
      const month = ("0" + (dateObj.getMonth() + 1)).slice(-2);
      const year = dateObj.getFullYear();
      const date = `${day}/${month}/${year}`;

      // Return formatted date and time
      return (
        <div>
          <div>{date}</div>
          <div>{time}</div>
        </div>
      );
    },
  },
  {
    Header: "גורם מעדכן",
    accessor: "updater_user_name",
  },
  {
    Header: "סוג עדכון",
    accessor: "type",
  },
  {
    Header: "ערך ישן מזומן",
    accessor: "cash_old_value",
    Cell: PriceCell,
  },
  {
    Header: "ערך חדש מזומן",
    accessor: "cash_new_value",
    Cell: PriceCell,
  },
  {
    Header: "ערך ישן אשראי",
    accessor: "credit_old_value",
    Cell: PriceCell,
  },
  {
    Header: "ערך חדש אשראי",
    accessor: "credit_new_value",
    Cell: PriceCell,
  },
];
